import Vue from 'vue';
import _ from 'lodash';
import { inject, injectable } from '@/inversify';

import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import THRESHOLD from '@/modules/compsets/constants/treshhold.constant';
import Percent from '@/modules/common/types/percent.type';
import CompsetModel from '@/modules/compsets/models/compset.model';

import CompsetsApiService, { CompsetsApiServiceS } from '@/modules/compsets/compsets-api.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CacheService, { CacheServiceS, MODULES } from '@/modules/common/services/cache/cache.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';

import CompsetsStore from './store/compsets.store';
import DocumentFiltersStore from '../document-filters/store/document-filters.store';

import { CompsetCreationBody } from './interfaces/compset-creation-body.interface';
import { CompsetSettings, HotelNetConfig } from './interfaces';
import { objectToCamel } from '../common/filters/keys-to-camel.filter';
import { NON_CAMEL_CASED_PROVIDER_PATTERNS } from './constants';

export const CompsetsServiceS = Symbol.for('CompsetsServiceS');
@injectable()
export default class CompsetsService {
    @inject(CompsetsApiServiceS) private compsetsApiService!: CompsetsApiService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @inject(CacheServiceS) private cacheService!: CacheService;

    readonly storeState: CompsetsStore = this.storeFacade.getState('CompsetsStore');
    private readonly documentFiltersStoreState: DocumentFiltersStore = this.storeFacade.getState('DocumentFiltersStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.userService.currentHotelId,
            ],
            () => {
                this.storeState.loading.reset();
            },
        );
    }

    get compsets() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.compsets;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get localCompsets() {
        return this.storeState.localCompsets;
    }

    get onboarding() {
        return this.storeState.onboarding;
    }

    get currentCompset() {
        const compset = this.getCompset(this.documentFiltersStoreState.settings.compsetId);

        return compset;
    }

    get currentMarketId() {
        const compset = this.currentCompset;

        if (compset === null) {
            return null;
        }

        return compset.marketId;
    }

    get competitors(): number[] | null {
        return this.currentCompset ? this.currentCompset.competitors : null;
    }

    get poses() {
        const allPoses = (this.compsets || []).map(compset => compset.pos).flat();
        const mainPoses = (this.compsets || []).map(compset => compset.mainPos);

        return [...new Set([...mainPoses, ...allPoses])];
    }

    async loadData() {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return false;
        }

        const compsets = await this.compsetsApiService
            .getCompsets(currentHotelId!);

        this.storeState.compsets = compsets;

        if (compsets === null || !compsets.length) {
            this.documentFiltersStoreState.settings.compsetId = null;
        } else {
            const firstMain = compsets.find(c => c.isMain);

            this.documentFiltersStoreState.settings.compsetId = firstMain
                ? firstMain.id
                : compsets[0].id;
        }

        this.resetUpdatedCompsets();

        return true;
    }

    createCompset(compsetData?: CompsetCreationBody) {
        // eslint-disable-next-line no-param-reassign
        compsetData = compsetData || this.storeState.onboarding.data;

        return this.compsetsApiService
            .createCompset(compsetData);
    }

    getLocalCompset(compset: CompsetModel | null) {
        const { localCompsets } = this.storeState;

        if (!localCompsets) {
            return null;
        }

        const localCompset = localCompsets
            .find(currentCompset => currentCompset.id === compset?.id);

        return localCompset || null;
    }

    getCompset(compsetId: string | null) {
        if (!compsetId) return null;

        const neededCompset = this.compsets && (this.compsets.find(compset => compset.id === compsetId) || null);
        const { isClusterUser, isChainUser } = this.userService;

        if (compsetId !== null && !neededCompset && (isChainUser || isClusterUser)) {
            return this.clusterCompsetsService.getCompsetById(compsetId);
        }

        return neededCompset;
    }

    getCompsetsByHotel(hotelId: number) {
        return (this.compsets || []).filter(compset => compset.ownerHotelId === hotelId);
    }

    getCompsetByType(compsetType: COMPSET_TYPE) {
        if (!this.compsets) {
            return null;
        }
        return this.compsets.find(compset => compset.type === compsetType) || null;
    }

    setUpdatedCompsetName(compset: CompsetModel, updatedName: string) {
        const localCompset = this.getLocalCompset(compset);

        if (localCompset) {
            localCompset.name = updatedName;
        }
    }

    setUpdatedCompsetType(compset: CompsetModel, updatedType: COMPSET_TYPE) {
        const localCompset = this.getLocalCompset(compset);

        if (localCompset) {
            localCompset.type = updatedType;
        }
    }

    validateUpdatedCompsets(activeCompset: CompsetModel | null) {
        const { localCompsets } = this.storeState;

        if (!localCompsets || !activeCompset) {
            return [];
        }

        const validateErrors = [] as Error[];

        if (!activeCompset) {
            validateErrors.push(new Error('Please select compset.'));
        } else {
            if (!activeCompset.name) {
                validateErrors.push(new Error('Please enter compset name.'));
            }
            if (!activeCompset.competitors.length) {
                validateErrors.push(new Error('Please select one hotel to comparison.'));
            }
            if (activeCompset.competitors.length > 10) {
                validateErrors.push(new Error('Please select less then 10 hotels per one compset.'));
            }
        }

        const newCompetitors = localCompsets
            .reduce((compsetitors: number[], compset) => compsetitors.concat(compset.competitors), []);
        const compsetitorsSet = new Set(newCompetitors);
        const totalUniqCompetitors = Array.from(compsetitorsSet);

        if (totalUniqCompetitors.length > 15) {
            validateErrors.push(new Error('Please select less then 15 unique hotels for all compsets.'));
        }

        return validateErrors;
    }

    async updateCompset(activeCompset: CompsetModel | null) {
        const errors = this.validateUpdatedCompsets(activeCompset);

        const { localCompsets } = this.storeState;
        if (!localCompsets || !localCompsets.length || !activeCompset) {
            return errors;
        }

        if (errors.length) {
            return errors;
        }

        const updatedCompsets = await this.compsetsApiService.updateCompsets([activeCompset]);

        if (updatedCompsets) {
            this.storeState.compsets = this.storeState.compsets!.map(compset => {
                const updatedCompset = updatedCompsets
                    .find(updated => updated.id === compset.id);
                return updatedCompset || compset;
            });
            this.resetUpdatedCompsets();
        }

        return [];
    }

    async updateCompsetLos(los: number[]) {
        const { currentCompset } = this;
        if (!currentCompset) {
            return;
        }

        const updatedCompset = await this.compsetsApiService.updateCompsetLos({ ...currentCompset, los });
        const compsets = this.storeState.compsets as CompsetModel[];
        if (updatedCompset) {
            const index = compsets.findIndex(item => item.id === updatedCompset[0].id);
            Vue.set(compsets, index, updatedCompset[0]);
            this.resetUpdatedCompsets();
        }
    }

    resetUpdatedCompsets() {
        const { compsets } = this.storeState;

        if (!compsets) {
            this.storeState.localCompsets = null;
        } else {
            this.storeState.localCompsets = JSON.parse(JSON.stringify(compsets));
        }
    }

    maxThreshold(compsetId?: string) : number | null {
        const compset = !compsetId ? this.currentCompset : this.getCompset(compsetId);
        return compset ? compset.maxThreshold : null;
    }

    minThreshold(compsetId?: string) : number | null {
        const compset = !compsetId ? this.currentCompset : this.getCompset(compsetId);
        return compset ? compset.minThreshold : null;
    }

    thresholdRange(compsetId?: string): { from: Percent[], to: Percent[] } {
        const range: { from: Percent[], to: Percent[] } = { from: [], to: [] };

        const nextValue = (value: number) => Number((value + THRESHOLD.STEP).toFixed(2));

        for (let value = THRESHOLD.MIN_RANGE; value <= THRESHOLD.MAX_RANGE; value = nextValue(value)) {
            range.from.unshift(value);
            range.to.unshift(value);
        }

        const minCompsetThreshold = this.minThreshold(compsetId);
        if (minCompsetThreshold !== null) {
            range.from.unshift(minCompsetThreshold);
        }

        const maxCompsetThreshold = this.maxThreshold(compsetId);
        if (maxCompsetThreshold !== null) {
            range.to.unshift(maxCompsetThreshold);
        }

        range.from = Array.from(new Set(range.from)).sort((a, b) => b - a);
        range.to = Array.from(new Set(range.to)).sort((a, b) => b - a);

        return range;
    }

    async updateThresholds(minThreshold: Percent, maxThreshold: Percent) {
        if (!this.currentCompset) {
            return;
        }

        await this.compsetsApiService.updateThresholds(this.currentCompset.id, minThreshold, maxThreshold);

        const { currentCompset } = this;
        currentCompset.minThreshold = minThreshold;
        currentCompset.maxThreshold = maxThreshold;
    }

    getNetValue(hotelId: number, provider: string, pos: string, byCompsetId: string | null = null) {
        const id = byCompsetId || this.currentCompset?.id;
        if (!id) return null;

        const netRateVat = this.getCurrentNetCalculationSettings(id, provider, pos);
        if (!netRateVat) return null;
        if (!netRateVat[hotelId]) return null;

        return +netRateVat[hotelId].vat;
    }

    getExtraFees(hotelId: number, provider: string, pos: string, byCompsetId = null) {
        const id = byCompsetId || this.currentCompset?.id;
        if (!id) return null;

        const netRateVat = this.getCurrentNetCalculationSettings(id, provider, pos);
        if (!netRateVat) return null;
        if (!netRateVat[hotelId]) return null;

        return +netRateVat[hotelId].extraFees;
    }

    getCurrentNetCalculationSettings(compsetId: string, provider: string, pos: string) {
        const compset = this.getCompset(compsetId);
        if (!compset) return {};

        const { netRateVat } = compset.settings;

        if (!netRateVat) return {};

        netRateVat[provider] = netRateVat[provider] || {
            [pos]: {},
        };

        return netRateVat[provider][pos] || {};
    }

    async saveNetCalculationSettings(
        hotelId: number,
        compsetId: string,
        provider: string,
        pos: string,
        data: HotelNetConfig[],
    ) {
        const netCalculationData = {} as Record<number, any>; // NOTE: Payload
        const compsetData = this.getCompset(compsetId);

        if (!compsetData) return Promise.resolve(null);

        data.forEach(data => {
            netCalculationData[data.id] = _.mapKeys({
                vat: data.vat,
                extraFees: data.extraFees,
            }, (__, k) => _.snakeCase(k));
        });

        const payload = {
            [provider]: {
                [pos]: netCalculationData,
            },
        };

        const { settings } = await this.compsetsApiService
            .updateNetCalculationSettings(hotelId, compsetId, payload);

        this.cacheService.erase(MODULES.COMPSETS);
        compsetData.settings = objectToCamel(settings, NON_CAMEL_CASED_PROVIDER_PATTERNS) as CompsetSettings;

        return settings;
    }
}
