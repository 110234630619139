import { inject, injectable } from '@/inversify';

import Stateable from '@/modules/common/interfaces/stateable.interface';
import type Day from '@/modules/common/types/day.type';

import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';

import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesScanModel from '@/modules/common/modules/socket/models/rates-scan.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';

import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import RatesApiService, { RatesApiServiceS, RatesDownloadExcelForm, UnitedSettings } from '@/modules/rates/rates-api.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import RatesCommonService, { RatesCommonServiceS, AllChannelsCheckinDay } from '@/modules/common/modules/rates/rates-common.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import RatesDocumentAllModel from './models/rates-document-all.model';
import HotelRooms from '../common/interfaces/hotelRooms.interface';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';
import PRICE_SHOWN from './constants/price-shown.constant';
import PRICE from '../common/modules/rates/constants/price.enum';
import COMPSET_TYPE from '../compsets/constants/compset-type.constant';
import CompsetModel from '../compsets/models/compset.model';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import DEFAULT_LOS from '../document-filters/constants/default-los.constant';
import RatesScanProgressModel from '../common/modules/socket/models/rates-scan-progress.model';
import { RatesDocumentScanValues } from './models/rates-document-scan.model';

type RatesDocumentUnion = RatesDocumentModel | RatesDocumentAllModel;

export const RatesServiceS = Symbol.for('RatesServiceS');
@injectable()
export default class RatesService implements Stateable {
    @inject(RatesApiServiceS) private ratesApiService!: RatesApiService;
    @inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(SocketServiceS) private socketService!: SocketService;
    @inject(RatesAllServiceS) ratesAllService!: RatesAllService;
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;
    @inject(MealTypesServiceS) private mealTypeService!: MealTypesService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor(storeState: RatesStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        } else {
            this.storeFacade.watch(() => [
                this.documentFiltersService.storeState.settings.compsetId,
                this.documentFiltersService.storeState.settings.month,
                this.documentFiltersService.storeState.settings.year,
                this.documentFiltersService.storeState.settings.los,
                this.documentFiltersService.storeState.settings.pos,
                this.storeState.settings.provider,
                this.storeState.settings.numberOfGuests,
                this.storeState.settings.roomTypeId,
                this.storeState.settings.mealTypeId,
                this.storeState.settings.priceType,
                this.userService.currentHotelId,
                this.userService.viewAs,

                // NOTE Display currency must be last
                this.userSettingsService.displayCurrency,
            ], (n: (string | number)[], o) => {
                if (JSON.stringify(n) === JSON.stringify(o) || n.slice(0, n.length - 1).some(v => v === null)) {
                    return;
                }

                this.storeState.isPriceShownSwitchDisabled = true;
                this.resetLoading();
            });

            this.storeFacade.watch(() => [
                this.storeState.settings.roomTypeId,
                this.storeState.settings.mealTypeId,
                this.documentFiltersService.storeState.settings.competitors,
            ], () => {
                this.storeState.isPriceShownSwitchDisabled = true;
            });

            this.storeFacade.watch(() => [
                this.documentFiltersService.settings.priceShown,
            ], () => {
                this.ratesCommonService
                    .redefineRankForRooms(this.data as RatesDocumentModel);
            });

            this.socketService.onRatesScan(this.handleScanUpdate.bind(this));
            this.socketService.onRatesScanProcessUpdate(this.handleScanProgressUpdate.bind(this));
        }

        this.storeFacade.watch(
            () => [
                this.userSettingsService.defaultFilters.price,
                this.userSettingsService.defaultFilters.numberOfGuests,
                this.userSettingsService.defaultFilters.mealType,
            ],
            (n, o) => {
                // NOTE If document not loaded yet, but there was redirrect from insights page, take default meal type from insights.
                const insightsMealTypeId = Number(sessionStorage.getItem('insightsMealType')) || null;
                if (insightsMealTypeId) {
                    sessionStorage.removeItem('insightsMealType');
                    this.settings.mealTypeId = this.mealTypeService.getMealType(insightsMealTypeId)?.id || -1;
                }

                if (JSON.stringify(n) === JSON.stringify(o)) {
                    return;
                }

                const { defaultFilters } = this.userSettingsService;

                // NOTE Set default filters if no apropriate value already set
                if (!this.settings.priceType) {
                    this.settings.priceType = defaultFilters.price;
                }

                if (!this.settings.numberOfGuests) {
                    this.settings.numberOfGuests = defaultFilters.numberOfGuests;
                }

                if (this.settings.mealTypeId === null) {
                    this.settings.mealTypeId = this.mealTypeService.getMealType(defaultFilters.mealType)?.id || -1;
                }
            },
            { immediate: true },
        );

        this.storeFacade.watch(
            () => this.mealTypeService.mealTypes,
            () => {
                const { mealTypes } = this.mealTypeService;
                const { defaultFilters } = this.userSettingsService;

                if (mealTypes.length <= 1 && this.settings.mealTypeId !== -1) { return; }

                const neededMealType = this.mealTypeService.getMealType(defaultFilters.mealType)!;
                this.settings.mealTypeId = neededMealType ? neededMealType.id : -1;
            },
        );
    }

    async loadData(docSettings?: DocumentFiltersModel): Promise<boolean> {
        const { settings: defaultDocumentSettings } = this.documentFiltersService;
        const { settings: ratesSettings } = this.storeState;
        const { displayCurrency } = this.userSettingsService;
        const { mealTypes } = this.mealTypeService;

        const documentSettings = false
            || docSettings
            || defaultDocumentSettings;

        const isSettingsNotValid = false
            || documentSettings.compsetId === null
            || documentSettings.los === null
            || documentSettings.pos === null
            || mealTypes.length === 0
            || ratesSettings.provider === null
            || ratesSettings.priceType === null
            || ratesSettings.mealTypeId === null
            || ratesSettings.numberOfGuests === null;

        if (!documentSettings.pos) {
            const compset = this.compsetsService.getCompset(documentSettings.compsetId);
            documentSettings.pos = compset ? compset.mainPos : documentSettings.pos;

            return false;
        }

        if (isSettingsNotValid) {
            return false;
        }

        const compset = this.compsetsService.currentCompset;
        const availableLoses = this.providersService.isDisabledProvider(ratesSettings.provider)
            ? compset?.los || []
            : DEFAULT_LOS;

        if (!availableLoses.includes(documentSettings.los!)) {
            [documentSettings.los] = availableLoses;
            return false;
        }

        this.storeState.document = null;

        const settings = {
            ...documentSettings,
            ...ratesSettings,
        } as UnitedSettings;

        const ratesDocument = await this.ratesApiService
            .getRatesDocument(settings, displayCurrency);

        this.storeState.document = ratesDocument || this.storeState.document;

        // NOTE Set scan info from document to scan model
        if (this.storeState.document && this.storeState.document instanceof RatesDocumentModel) {
            const scanInfo = {
                ...this.storeState.document.scan,
                ratio: this.storeState.scan.values.ratio,
                documentId: String(this.storeState.document.id),
            } as RatesDocumentScanValues;

            this.storeState.scan.update(scanInfo);
        } else {
            // NOTE All channels don't have scans
            this.storeState.scan.reset();
        }

        if (this.storeState.document) {
            this.ratesCommonService
                .redefineRankForRooms(this.storeState.document as RatesDocumentModel);
        }

        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    set data(value: RatesDocumentModel | RatesDocumentAllModel | null) {
        this.storeState.document = value;
    }

    get scan() {
        return this.storeState.scan;
    }

    set settings(value: RatesSettingsModel) {
        this.storeState.settings = value;
    }

    get settings() {
        return this.storeState.settings;
    }

    get currency() {
        return this.ratesCommonService.currency(this.data);
    }

    get documentId() {
        return this.data && this.data.id;
    }

    get showDiff() {
        return this.storeState.showPriceDiff;
    }

    set showDiff(value: boolean) {
        this.storeState.showPriceDiff = value;
    }

    get finishScanDate() {
        if (!this.scan) {
            return null;
        }

        return this.scan.values.endTime || null;
    }

    get scanEstimatedTime() {
        return this.scan.values.estimation;
    }

    set scanId(value: string) {
        if (!this.data) {
            const newDoc = new RatesDocumentModel();
            newDoc.scan = { ...newDoc.scan, id: value };
            this.data = newDoc;
            this.scan.update({ id: value });
            return;
        }

        this.data = {
            ...this.data,
            scan: {
                ...(this.data as RatesDocumentModel).scan,
                id: value,
            },
        };

        this.scan.update({ id: value });
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get isIntradayLoading() {
        return this.storeState.intraday.loading.isLoading();
    }

    get isAllChannelsMode() {
        return this.settings.provider === 'all';
    }

    get isCheapestChannel() {
        return this.settings.provider === 'cheapest';
    }

    get availableProviders() {
        if (!this.data) return [];
        return (this.data as RatesDocumentAllModel).availableProviders;
    }

    resetLoading() {
        this.storeState.loading.reset();
        this.storeState.intraday.loading.reset();
        this.ratesCommonService.loadRemainingScans(
            this.userService.currentHotelId,
            this.storeState.settings.provider,
        );
    }

    getCheckinDay(day: Day) {
        return this.ratesCommonService
            .getCheckinDay(day, this.data as RatesDocumentModel);
    }

    getAllRooms(day: Day): HotelRooms {
        return this.ratesCommonService
            .getAllRooms(day, this.data as RatesDocumentModel);
    }

    getCompetitorsRooms(day: Day, selectedCompetitors?: number[]): HotelRooms {
        const allRooms = this.getAllRooms(day);

        if (this.isAllChannelsMode) {
            return allRooms;
        }

        const hotelId = this.userService.currentHotelId;
        const competitors = selectedCompetitors || this.documentFiltersService.competitors;

        if (!hotelId || !competitors) return {};

        const entries = competitors
            .map(hid => [hid, allRooms[hid]]);

        return Object.fromEntries(entries);
    }

    /**
     * Returns room for specified `hotelId` (type string means provider for all channels mode)
     */
    getRoom(day: Day, hotelId: number | string, priceShown?: PRICE_SHOWN) {
        return this.ratesCommonService
            .getRoom(day, hotelId, this.data as RatesDocumentModel, priceShown);
    }

    /**
     * **For Cheapest channel only**
     * Returns provider list for specific room
     */
    getRoomProviders(day: Day, hotelId: number, priceShown?: PRICE_SHOWN) {
        const specifiedPriceShown = priceShown || this.documentFiltersService.priceShown;

        if (!specifiedPriceShown) return [];

        return this.ratesCommonService
            .getRoomProviders(this.data as RatesDocumentModel, day, hotelId, specifiedPriceShown);
    }

    getHotelLink(day: Day, hotelId: number | string) {
        const checkinDay = this.getCheckinDay(day);

        if (!checkinDay) return null;

        if (this.isAllChannelsMode) {
            const cd = checkinDay as unknown as AllChannelsCheckinDay;

            if (!cd[hotelId]) return null;

            return cd[hotelId].link;
        }

        if (this.isNA(day, +hotelId)) return null;

        const hotel = checkinDay.hotels[+hotelId];

        return hotel ? hotel.link : null;
    }

    getHotelsFromCheckinDay(day: Day) {
        const checkinDay = this.getCheckinDay(day);

        if (!checkinDay) return null;

        return checkinDay.hotels || null;
    }

    getCompetitionPercent(day: Day, compset?: CompsetModel, priceShown?: PRICE_SHOWN) {
        if (this.isAllChannelsMode) {
            const averagePrice = this.ratesAllService.getAverageRoomsPrice(day);
            const highestPrice = this.ratesAllService.getHighestPrice(day);

            return (highestPrice - averagePrice) / averagePrice;
        }

        const hotelPrice = this.getPrice(day, undefined, priceShown);
        const comparePrice = compset
            ? this.getCompsetPriceByCompset(day, compset, priceShown)
            : this.getCompsetPrice(day, undefined, undefined, priceShown);

        if (!hotelPrice || !comparePrice) {
            return null;
        }

        return (hotelPrice - comparePrice) / comparePrice;
    }

    /**
     * Returns price for Hotel Room by its `hotelId` (default: current user hotel)
     * - if `hotelId` is string, it is provider name
     */
    getPrice(day: Day, hotelId?: number | string, priceShown?: PRICE_SHOWN) {
        const hid = hotelId || this.userService.currentHotelId;

        if (!hid) return null;

        const doc = this.data as RatesDocumentModel;

        const price = this.ratesCommonService
            .getPrice(day, +hid || hid, doc, priceShown || this.documentFiltersService.priceShown);

        return price;
    }

    getUpdateDate(day: Day) {
        return this.ratesCommonService
            .getUpdateDate(day, this.data as RatesDocumentModel);
    }

    getPriceList(day: Day) {
        const rooms = Object.values(this.getAllRooms(day)) as RatesDocumentItemModel[];

        return Object
            .values(rooms)
            .map(room => this.switchPrice(room))
            .filter(price => true
                && price
                && price !== PRICE.NA
                && price !== PRICE.SOLD_OUT) as number[];
    }

    getCompsetPriceByCompset(day: Day, compset: CompsetModel, priceShown?: PRICE_SHOWN) {
        if (!compset) return null;

        const competitorRooms = this.getCompetitorsRooms(day, compset.competitors);
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;

        return this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset.type, actualPriceShown);
    }

    getCompsetPrice(day: Day, compsetType?: COMPSET_TYPE, useAllCompetitors?: boolean, priceShown?: PRICE_SHOWN) {
        const compset = !compsetType
            ? this.compsetsService.currentCompset
            : { type: compsetType, competitors: undefined };

        if (!compset) return null;

        const competitorRooms = this.getCompetitorsRooms(day, useAllCompetitors ? compset.competitors : undefined);

        return this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset.type, priceShown || this.documentFiltersService.priceShown);
    }

    getCardAssessment(day: Day, priceShown?: PRICE_SHOWN) : ASSESSMENTS_TYPES | null {
        const { currentCompset } = this.compsetsService;
        if (!currentCompset) return null;

        const competitionPercent = this.getCompetitionPercent(day, undefined, priceShown);

        if (competitionPercent === null) return null;

        return this.ratesCommonService
            .getCardAssessment(competitionPercent, currentCompset);
    }

    getTableAssessment(price: number, day: Day) {
        const mainHotelId = this.userService.currentHotelId!;
        const doc = this.data as RatesDocumentModel;
        const { currentCompset: compset } = this.compsetsService;

        return this.ratesCommonService
            .getTableAssessment(price, day, mainHotelId, compset, doc);
    }

    getDemand(day: Day) {
        return this.ratesCommonService.getDemand(day, this.data as RatesDocumentModel);
    }

    getOccupancy(day: Day) {
        return this.ratesCommonService.getOccupancy(day, this.data as RatesDocumentModel);
    }

    getHotelLosRestriction(day: Day, hotelId?: number | string) {
        const hid = hotelId || this.userService.currentHotelId;

        return this.ratesCommonService.getHotelLosRestriction(day, hid!, this.data);
    }

    getScreenshot(day: Day, hotelId: number) {
        return this.ratesCommonService.getScreenshot(day, hotelId, this.data as RatesDocumentModel);
    }

    getHotelName(hotelId: number) {
        if (!this.data) return null;

        return this.data.hotelNames?.[hotelId] || null;
    }

    saveDocument(doc: RatesDocumentUnion | null) {
        this.storeState.document = doc;
    }

    myUpdateStatusDate(day: Day | undefined) {
        if (!day) {
            return null;
        }

        return this.getUpdateDate(day);
    }

    switchPrice(room: RatesDocumentItemModel | null, priceShown?: PRICE_SHOWN) {
        return this.ratesCommonService.switchPrice(room, priceShown);
    }

    minMaxPrices(excludeHotelId?: number | null): { minPrices: (number | null)[], maxPrices: (number | null)[] } {
        const { data } = this;
        const { days } = this.documentFiltersService;

        return this.ratesCommonService
            .minMaxPrices(data as RatesDocumentModel, days, excludeHotelId);
    }

    hasRoomMealType(day: Day, hotelId: number | string) {
        const currentRoom = this.getRoom(day, hotelId);

        if (!currentRoom) {
            return false;
        }

        const roomOnlyId = 0;
        return currentRoom.mealTypeId !== roomOnlyId;
    }

    hasRoomSameOccupancy(day: Day, hotelId: number | string) {
        const currentRoom = this.getRoom(day, hotelId);

        if (!currentRoom) {
            return false;
        }

        const { numberOfGuests } = this.storeState.settings;

        return currentRoom.occupancy === numberOfGuests;
    }

    hasRoomMultipleCancellation(day: Day, hotelId: number | string) {
        const currentRoom = this.getRoom(day, hotelId);

        if (!currentRoom) {
            return false;
        }

        return currentRoom.multipleCancellation;
    }

    isBasic(day: Day, hotelId: number) {
        const room = this.getRoom(day, hotelId);

        if (!room) return null;

        return room.isBasic;
    }

    isIntraday(day: Day, hotelId?: number) {
        if (this.isAllChannelsMode) return null;

        const actualHotelId = hotelId || this.userService.currentHotelId;
        if (!actualHotelId) return null;

        const room = this.getRoom(day, actualHotelId);
        if (!room) return null;

        return room.intraday || room.intradayBySpecialDate;
    }

    isSpecialIntraday(day: Day, hotelId?: number) {
        if (this.isAllChannelsMode) return false;

        const actualHotelId = hotelId || this.userService.currentHotelId;
        if (!actualHotelId) return false;

        const room = this.getRoom(day, actualHotelId);
        if (!room) return false;

        return !!room.intradayBySpecialDate;
    }

    isNoData(day: Day) {
        return this.ratesCommonService
            .isNoData(day, this.data as RatesDocumentModel);
    }

    isOutOfRange() {
        return this.ratesCommonService.isOutOfRange(this.data);
    }

    isNA(day: Day, hotelId: number | string, priceShown?: PRICE_SHOWN) {
        if (this.isNoData(day)) return false;

        const price = this.getPrice(day, hotelId, priceShown);

        return false
            || price === PRICE.NA
            || price === null;
    }

    isSoldOut(day: Day, hotelId: number | string, priceShown?: PRICE_SHOWN) {
        if (this.isNA(day, hotelId)) return false;
        if (this.isNoData(day)) return false;

        const price = this.getPrice(day, hotelId, priceShown);

        return price === PRICE.SOLD_OUT;
    }

    isRoomPriceValid(room: RatesDocumentItemModel | null) {
        const price = this.switchPrice(room);
        return true
                && price
                && price !== PRICE.NA
                && price !== PRICE.SOLD_OUT;
    }

    isScanAvailable(day?: Day): boolean {
        if (!this.compsetsService.currentCompset?.isActive) {
            return false;
        }

        return this.ratesCommonService.isScanAvailable(this.storeState.settings, day);
    }

    async triggerScan(day?: Day) {
        const { settings: docSettings } = this.documentFiltersService;
        const { settings: ratesSettings } = this;

        if (!this.data) {
            const newDoc = new RatesDocumentModel();
            newDoc.scan = { ...newDoc.scan };
            this.data = newDoc;
        }

        this.scan.update({ status: SCAN_STATUS.IN_PROGRESS });
        const scanEstimation = day !== undefined ? this.scan.values.estimationOneDay * (30 - day) : this.scan.values.estimationOneDay;
        this.scan.start(scanEstimation);

        const res = await this.ratesCommonService
            .triggerScan(ratesSettings, docSettings, day);

        if (!res) {
            this.scan.update({ status: SCAN_STATUS.FINISHED });
            this.scan.finish();
            return true;
        }

        this.scan.update({ id: res.scanId });

        return true;
    }

    private handleScanUpdate(data: RatesScanModel) {
        if (this.data && this.data.id === data.ratesDocumentId) {
            this.storeState.loading.reset();
        }
    }

    private handleScanProgressUpdate(data: RatesScanProgressModel) {
        if (this.data && this.scan.values.documentId === String(data.ratesDocumentId)) {
            this.scan.update({ ratio: data.ratio });
        }
    }

    async checkScanStatus() {
        if (!this.data) {
            return null;
        }

        const { id, documentId } = this.scan.values;
        if (!id || !documentId) {
            return null;
        }

        const { status } = await this.ratesApiService.checkScanStatus(documentId, id);
        return status;
    }

    // Intraday methods

    async loadIntradayData(day: Day, specialDate: boolean = false) {
        const { settings: docSettings } = this.documentFiltersService;
        const { settings: ratesSettings } = this;
        const { displayCurrency: currency } = this.userSettingsService;
        const { currentHotelId: fornovaId } = this.userService;
        const unitedSettings = {
            ...docSettings,
            ...ratesSettings,
        } as UnitedSettings;

        const isSettingsNotValid = false
            || unitedSettings.compsetId === null
            || unitedSettings.los === null
            || unitedSettings.pos === null
            || unitedSettings.provider === null
            || fornovaId === null;

        if (isSettingsNotValid) {
            return false;
        }

        // NOTE: Disabled until BE implement it on their side
        //
        // const rooms = this.getAllRooms(day);
        // const hotelFilters = Object.entries(rooms)
        //     .map(entry => {
        //         const [hotelId, room] = entry;
        //         if (!room) return null;

        //         return new IntradayHotelFilterModel(
        //             +hotelId,
        //             room.roomName,
        //             this.mealTypeService.getMealType(room.mealTypeId)?.name || '',
        //         );
        //     })
        //     .filter(Boolean) as IntradayHotelFilterModel[];

        this.storeState.intraday.document = await this.ratesApiService
            .getIntradayPrice(day, fornovaId as number, unitedSettings, currency, undefined, specialDate);
        this.storeState.intraday.day = day;

        return true;
    }

    getIntradayData(day: Day) {
        if (day !== this.storeState.intraday.day && !this.isIntradayLoading) {
            this.storeState.intraday.loading.reset();
            this.storeState.intraday.document = null;
        }

        const competitors = this.documentFiltersService.competitors || [];
        const isSpecialDate = this.isSpecialIntraday(day) || competitors.some(c => this.isSpecialIntraday(day, c));

        this.helperService.dynamicLoading(this.storeState.intraday.loading, () => this.loadIntradayData(day, isSpecialDate));
        return this.storeState.intraday.document;
    }

    getMedianPrice(day: Day) {
        return this.getCompsetPrice(day, COMPSET_TYPE.MEDIAN);
    }

    intradayHotelRooms(day: Day) {
        if (!this.settings) {
            return null;
        }

        const intradayData = this.getIntradayData(day);

        if (!intradayData || !intradayData.hotels || !Object.keys(intradayData.hotels).length) {
            return null;
        }

        let hotelRooms: { [hotelId: number]: RatesDocumentItemModel } | null = null;

        hotelRooms = this.ratesCommonService.getAllRoomsByHotels(intradayData.hotels);

        return hotelRooms;
    }

    intradayByHotelId(day: Day, hotelId: number, priceShown: PRICE_SHOWN) {
        const hotelRooms = this.intradayHotelRooms(day);

        if (!hotelRooms) {
            return null;
        }

        const room = hotelRooms[hotelId];

        return this.ratesCommonService
            .switchPrice(room, priceShown);
    }

    intradayMedian(day: Day) {
        const hotelRooms = this.intradayHotelRooms(day);
        if (!hotelRooms) {
            return null;
        }

        return this.ratesCommonService.getMedianPrice(hotelRooms);
    }

    async getExcel(params: RatesDownloadExcelForm, toEmail = false, onDemand = false) {
        const { currentHotelId: fornovaId } = this.userService;

        if (!fornovaId) {
            return false;
        }

        const excelData = await this.ratesApiService
            .getExcelDocument({ ...params, fornovaId }, toEmail, onDemand);

        if (excelData) {
            if (!toEmail && !onDemand) {
                await this.customNotificationService
                    .handleExcel(excelData);
            }
        }

        return true;
    }

    async downloadExcelDirectly(
        _compsetId: string,
        dateStart: string,
        dateEnd: string,
        query: {
            pos: string, los: string, priceType: string, providers: string,
            numberOfGuests: string, roomTypeId: string, mealTypeId: string,
        },
    ): Promise<void> {
        const monthRange: string[] = [dateStart, dateEnd];
        const numOfGuests = Number(query.numberOfGuests) || this.userSettingsService.defaultFilters.numberOfGuests;

        const convertStringToArray = (str: string, type: string): any[] => {
            if (!str) {
                return [];
            }
            if (type === 'string') {
                return str.split(',').map(String);
            }
            if (type === 'int') {
                return str.split(',').map(Number);
            }
            return [];
        };

        const data = {
            compsetId: _compsetId,
            pos: convertStringToArray(query.pos, 'string')[0],
            los: convertStringToArray(query.los, 'int')[0],
            priceType: convertStringToArray(query.priceType, 'string'),
            provider: convertStringToArray(query.providers, 'string')[0],
            numberOfGuests: numOfGuests,
            roomTypeId: convertStringToArray(query.roomTypeId, 'int'),
            mealTypeId: convertStringToArray(query.mealTypeId, 'int'),
            monthrange: monthRange,
            competitors: [],
            priceShown: PRICE_SHOWN.TOTAL,
            displayCurrency: 'USD',
            columns: {
                market_demand: true,
                occupancy: true,
                rank: true,
                diff_delta: true,
                diff_precent: true,
                median: true,
                mealType: true,
                roomType: true,
                roomName: true,
            },
        };

        await this.getExcel(data);
    }
}
