import Item from '@/modules/common/interfaces/item.interface';
import InsightTypeModel from '@/modules/insights/models/insight-type.model';

export class InsightFilterSettingsModel {
    dateRange: string = '7';
    insightTypes: InsightTypeModel[] | null = null;
    providers: string[] | null = null;
}

export class InsightFilterOptionsModel {
    dateRange: Item<string>[] = [
        {
            name: 'titles.today',
            value: '1',
        }, {
            name: 'insights.nextDays',
            value: '7',
        }, {
            name: 'insights.nextDays',
            value: '14',
        }, {
            name: 'insights.nextDays',
            value: '30',
        }, {
            name: 'insights.nextDays',
            value: '60',
        }, {
            name: 'insights.nextDays',
            value: '90',
        },
    ];
    insightTypes: InsightTypeModel[] = [];
    providers: Item<string>[] = [];
}
