/* eslint-disable camelcase */
import { Expose, Transform } from 'class-transformer';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import { DayDeviceInsightData } from '../types';

interface BEHotel {
    rooms: {
        [roomTypeId: number]: {
            price: { shown_price: number, net_price: number, total_price: number };
            price_type: string;
            room_name: string;
            meal_type_id: string;
            currency: string;
        }[]
    }
}

export default class InsightDayDeviceModel {
    @Expose()
    @Transform((_, plain) => {
        const dayRateData = {} as Record<string, DayDeviceInsightData>;
        const priceShownKey = plain.priceTypes ? (plain.priceTypes as string[])[0] || 'shown_price' : 'shown_price';
        const priceShown = priceShownKey.replace('_price', '') as PRICE_SHOWN;

        // Set main and compared price for hotels, which have main provider data
        Object.entries<BEHotel>(plain.providerData).forEach(([fId, hotel]) => {
            if (!hotel.rooms) {
                return;
            }

            const room = Object.keys(hotel.rooms).length ? hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0] : null;
            const price = room?.price[priceShownKey as keyof typeof room.price];

            const comparedHotelData = plain.oppositeProviderData[fId] || null as BEHotel | null;
            const compareRoom = comparedHotelData?.rooms
                ? comparedHotelData.rooms[parseInt(Object.keys(comparedHotelData.rooms)[0], 10)][0]
                : null;
            const comparePrice = compareRoom?.price[priceShownKey as keyof typeof compareRoom.price];

            dayRateData[fId] = {
                name: plain.hotelNames[fId] || fId,
                price: Number.isFinite(price) ? price as number : null,
                currency: room?.currency || null,
                comparePrice: Number.isFinite(comparePrice) ? comparePrice as number : null,
                compareCurrency: compareRoom?.currency || null,
                filters: {
                    roomName: room?.room_name || null,
                    mealTypeId: Number.isFinite(room?.meal_type_id) ? room!.meal_type_id : null,
                    priceType: room?.price_type || null,
                    priceShown,
                },
            };
        });

        // Fill compared data for hotels, which doesn't have main provider data
        Object.entries<BEHotel>(plain.oppositeProviderData).forEach(([fId, hotel]) => {
            if (!hotel.rooms) {
                return;
            }

            if (dayRateData[fId]) {
                return;
            }

            const room = Object.keys(hotel.rooms).length ? hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0] : null;
            const price = room?.price[priceShownKey as keyof typeof room.price];
            const comparePrice = {
                comparePrice: Number.isFinite(price) ? price as number : null,
                compareCurrency: room?.currency || null,
            };

            dayRateData[fId] = {
                name: plain.hotelNames[fId] || fId,
                price: null,
                currency: null,
                filters: {
                    roomName: room?.room_name || null,
                    mealTypeId: Number.isFinite(room?.meal_type_id) ? room!.meal_type_id : null,
                    priceType: room?.price_type || null,
                    priceShown,
                },
                ...comparePrice,
            };
        });

        return dayRateData;
    })
    data!: Record<string, DayDeviceInsightData>;

    @Expose()
    filters!: Record<string, string | number>;

    @Expose()
    @Transform((_, plain) => plain.alertData?.message || '')
    message!: string;
}
