import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import { IGuard } from '@/router/interfaces/guard.interface';
import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { USER_LEVELS } from '@/modules/user/constants';

export const FeaturesGuardS = Symbol.for('FeaturesGuardS');
@injectable()
export default class FeaturesGuard implements IGuard {
    @inject(UserNavigationServiceS) private userNavigationService!: UserNavigationService;
    @inject(UserServiceS) private userService!: UserService;

    async canActivate(to: Route) {
        const [level] = (to.name || '').split('.') as USER_LEVELS[];
        let hotelId: number | undefined = to.params?.hotelId
            ? +to.params.hotelId
            : undefined;

        // NOTE: On Hotel user there might be a case when hotelId is null
        if (Number.isNaN(hotelId)) {
            hotelId = this.userService.currentHotelId!;
        }

        // NOTE: If we won't do it, we will have a bug when
        //       user try to navigate via browser's BACK button
        await this.userService.setViewAs(level, hotelId);

        return this.userNavigationService.checkIsRouteAllowed(to.fullPath);
    }
}
