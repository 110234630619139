import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';
import { USER_LEVELS } from '@/modules/user/constants';

export const HotelGuardS = Symbol.for('HotelGuardS');
@injectable()
export default class HotelGuard implements IGuard {
    @inject(UserServiceS) private userService!: UserService;

    async canActivate(to: Route) {
        const [level] = (to.name || '').split('.') as USER_LEVELS[];
        const { hotelId } = to.params;

        if (!this.userService.currentHotelId) {
            if (level === USER_LEVELS.HOTEL && !hotelId) return false;

            await this.userService.setViewAs(level, +hotelId);
        }

        const isViewAsRoute = true
            && this.userService.userLevel !== USER_LEVELS.HOTEL
            && !!to.params.hotelId;

        return isViewAsRoute
            || this.userService.userLevel === USER_LEVELS.HOTEL
            || this.userService.viewAs === USER_LEVELS.HOTEL;
    }
}
