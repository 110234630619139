import { inject, injectable } from '@/inversify';
import PROVIDERS_REGEXP from '@/modules/alerts/constants/providers-regexp.const';
import AlertsApiService, { AlertsApiServiceS } from '@/modules/alerts/alerts-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import AlertsStore from '@/modules/alerts/store/alerts-store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import CacheService, { ALERT_METHODS, CacheServiceS, MODULES } from '../common/services/cache/cache.service';
import { USER_LEVELS } from '../user/constants';

export const AlertsServiceS = Symbol.for('AlertsServiceS');
@injectable()
export default class AlertsService implements Stateable {
    @inject(AlertsApiServiceS) private alertsApiService!: AlertsApiService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(CacheServiceS) private cacheService!: CacheService;

    readonly storeState: AlertsStore = this.storeFacade.getState('AlertsStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.userService.currentHotelId,
                this.userService.viewAs,
            ],
            this.resetService.bind(this),
        );

        this.loadData = this.cacheService
            .memorize(MODULES.ALERTS, ALERT_METHODS.getAlerts, this.loadData.bind(this), 1);
    }

    get alerts() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.alerts.concat().sort((a, b) => b.date.getTime() - a.date.getTime());
    }

    async loadData() {
        const { routeBranch } = this.userService;
        this.storeState.alerts = [];

        switch (routeBranch) {
            case USER_LEVELS.CHAIN:
            case USER_LEVELS.CLUSTER:
                this.storeState.alerts = await this.alertsApiService.getClusterAlerts() || [];
                return true;
            case USER_LEVELS.HOTEL:
                this.storeState.alerts = await this.alertsApiService.getCiAlerts() || [];
                return true;
            case USER_LEVELS.CAR:
                this.storeState.alerts = await this.alertsApiService.getCarAlerts() || [];
                return true;
            default:
                return true;
        }
    }

    async markAlertAsRead(id: string) {
        this.alerts
            .find(a => a.id === id)!
            .read = true;

        await this.alertsApiService.markAlertAsRead(id);
    }

    async markAlertAsUnread(id: string) {
        const alertData = this.alerts.find(a => a.id === id);

        if (alertData) {
            alertData.read = false;
        }

        await this.alertsApiService.markAlertAsUnread(id);
    }

    async markAllAlertsAsRead() {
        const alertIds = this.alerts.map(a => a.id);
        await this.alertsApiService.markAlertsAsRead(alertIds);

        this.storeState.loading.reset();
    }

    async deleteAlert(id: string) {
        this.storeState.alerts = this.storeState.alerts.filter(a => a.id !== id);
        await this.alertsApiService.deleteAlert(id);
    }

    private resetService(
        [hotelId, viewAs]: [number, USER_LEVELS],
        [hotelIdOld, viewAsOld]: [number, USER_LEVELS],
    ) {
        if (
            hotelId === hotelIdOld
            && viewAs === viewAsOld
        ) {
            return;
        }

        this.storeState.loading.reset();
        this.cacheService.erase(MODULES.ALERTS);
    }

    getProviderName(message: string) {
        const result = message.match(PROVIDERS_REGEXP);
        return result ? result[0] : null;
    }

    isLoading() {
        return this.storeState.loading.isLoading();
    }
}
