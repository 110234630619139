import { Expose, Transform } from 'class-transformer';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import type ICheckinDate from '@/modules/rates/interfaces/checkin-date.interface';
import { TrendsDocument, TrendEntityData } from '../types';

/** Transform RatesDocumentModel & { date: Date } into trends initial document.
 * Only for hotel level rates price history.
 * @field trendData: list of trend items, 0 element is initial rates document
 * @field date: requested checkin date
 * @field documentId: id of initial rates doucment
 * @field names: key-value pairs of hotel names
 * @field exchangeRate: currency excange rate
 * @field providerName: name of initial rate document's provider
 * @field currency: document's currency
 */
export class HotelRatesToTrendsModel implements TrendsDocument {
    @Expose()
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => {
        const { checkinDates } = plain;

        if (!checkinDates || !checkinDates[plain.date.getDate()]) {
            return null;
        }

        const checkinDate = checkinDates[plain.date.getDate()] as ICheckinDate;
        const hotelIds = Object.keys(checkinDate.hotels);
        const data = hotelIds.reduce((acc, hotelId) => {
            if (!checkinDate.hotels[hotelId]) {
                return acc;
            }

            const {
                screenshot, losRestriction, link, updateDate, rooms,
            } = checkinDate.hotels[hotelId];
            const roomTypeId = (rooms && Object.keys(rooms).length) ? Number(Object.keys(rooms)[0]) : null;
            const room = roomTypeId !== null ? rooms[roomTypeId][0] : null;

            return {
                ...acc,
                [hotelId]: {
                    rooms: room ? [room] : [],
                    entityType: 'hotel',
                    updateDate,
                    link,
                    losRestriction,
                    screenshot,
                } as TrendEntityData,
            };
        }, {} as { [hotelId: string]: TrendEntityData });

        return [{
            data,
            demand: checkinDate.demand,
            occupancy: checkinDate.occupancy,
            updateDate: checkinDate.updateDate,
        }];
    })
    trendData!: TrendsDocument['trendData'];

    @Expose()
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => plain.date)
    date!: Date;

    @Expose()
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => plain.id)
    documentId!: number;

    @Expose()
    // Record<number, string> to Record<string, string>
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => Object.keys(plain.hotelNames)
        .reduce((acc, nameKey) => ({ ...acc, [nameKey]: plain.hotelNames[Number(nameKey)] }), {} as Record<string, string>))
    names!: Record<string, string>;

    @Expose()
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => plain.exchangeRate)
    exchangeRate!: number;

    @Expose()
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => plain.providerName)
    providerName!: string;

    @Expose()
    @Transform((_, plain: RatesDocumentModel & { date: Date }) => plain.currency)
    currency?: string;
}
